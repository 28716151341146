import { createApp } from "vue/dist/vue.esm-bundler";
import App from "./App.vue";
import Dynamic from "./components/dynamic/dynamic.vue";
import VueLazyload from "@jambonn/vue-lazyload";
import globalMixin from "./helpers/mixin";

//Bootstrap Related
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import $ from "jquery";
window.$ = $;
import "jquery-easing";


//Swiper Styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/a11y";

// FontAwesome Related
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faMessagesQuestion, faDoorClosed, faCoinVertical, faGameConsoleHandheld, faShieldCheck, faHundredPoints, faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons"
import { faCirclePlus, faCircleMinus } from "@fortawesome/pro-regular-svg-icons";
// import { faSlotMachine as two } from "@fortawesome/pro-light-svg-icons";
import { faSlotMachine, faFireFlameCurved, faSparkles, faCards, faEllipsis, faCoins, faTireFlat, faTv} from "@fortawesome/pro-thin-svg-icons";
import { faHouse, faPlay, faLock, faExpand, faThumbsDown, faThumbsUp, faExclamation, faUser, faSearch, faUserPlus, faClipboardCheck, fa1, fa2, fa3, fa4, faKey, faFile, faGift, faClockRotateLeft, faUserShield, faHand, faChevronLeft, faFilter, faDoorOpen, faSpinner, faCircleInfo, faWallet, faMessage, faGlobe, faChevronUp, faEye, faEnvelope, faSms, faStar, faShop } from "@fortawesome/free-solid-svg-icons";
import { faUser as faUserRegular, faFaceSmile } from '@fortawesome/free-regular-svg-icons';
library.add(faHouse, faPlay, faLock, faExpand, faThumbsDown, faThumbsUp, faExclamation, faUser, faUserPlus, faSearch, faClipboardCheck, fa1, fa2, fa3, fa4, faFile, faKey, faGift, faClockRotateLeft, faUserShield, faHand, faChevronLeft, faFilter, faDoorOpen, faSpinner, faCircleInfo, faWallet, faMessage, faGlobe, faChevronUp, faEye, faEnvelope, faSms, faStar, faShop, faFireFlameCurved, faSlotMachine, faSparkles, faCards, faEllipsis, faMessagesQuestion, faDoorClosed, faCoinVertical, faCoins, faTireFlat, faTv, faGameConsoleHandheld, faUserRegular, faShieldCheck, faHundredPoints, faCirclePlus, faCircleMinus, faFaceSmile, faTriangleExclamation);

const app = createApp(App);

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.mixin(globalMixin);

app.use(VueLazyload, {
    attempt: 1,
});

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("dynamic", Dynamic);
app.mount("#app");
