import axios from "axios";
import { getUrl } from "../helpers/helpers";

const axiosStrapiInstance = axios.create({
    baseURL: `${getUrl("strapi")}/graphql/`,
    timeout: 25000,
    headers: {
        "content-type": "application/json",
    },
});



const errorHandling = (error, myStore, requestName) => {
    // sentryError(requestName, error);
    if (error && error.code) {
        myStore.setAlert({ text: error.code, classes: "error" });
        return { success: false, message: error.code };
    } else {
        myStore.setAlert({ text: "GENERIC_ERROR", classes: "error" });
        return { success: false, message: "GENERIC_ERROR" };
    }
};


export { axiosStrapiInstance, errorHandling };
