import { defineStore } from "pinia";
import { getCookie } from "../helpers/helpers";
import { detectIncognito } from "detectincognitojs";

const loggedIn = () => {
    if (getCookie("expiresAt") !== "") {
        const currentDateAndTime = new Date();
        const expiresAtDateAndTime = new Date(getCookie("expiresAt"));
        var differenceInMilliseconds = expiresAtDateAndTime.getTime() - currentDateAndTime.getTime();
        return differenceInMilliseconds > 0;
    }
    return false;
};

export const useMyStore = defineStore("mine", {
    state: () => ({
        loggedIn: loggedIn(),
        disabledGameThumbnails: false,
        alertInfo: null,
        bannerInPromotionObject: null,
        closeRegisterAttempt: false,
        blockedRegister: false,
        promotionRoutesCreated: false,
        pagesRoutesCreated: false,
        showCookiePolicyPopUp: false,
        showIncognitoBrowserPopUp: false,
    }),
    actions: {
        setDisabledGameThumbnails(newValue) {
            this.disabledGameThumbnails = newValue;
        },
        setLoggedIn(newValue) {
            this.loggedIn = newValue;
        },
        setAlert(newValue) {
            this.alertInfo = Object.assign({}, newValue);
            setTimeout(function () {
                $(".my-alert").addClass("visible");
            }, 300);
            setTimeout(function () {
                $(".my-alert").removeClass("visible");
            }, 5000);
        },
        setCloseRegisterAttempt(newValue) {
            this.closeRegisterAttempt = newValue;
        },
        setBlockedRegister(newValue) {
            this.blockedRegister = newValue;
        },
        setPromotionRoutesCreated(newValue) {
            this.promotionRoutesCreated = newValue;
        },
        setPagesRoutesCreated(newValue) {
            this.pagesRoutesCreated = newValue;
        },
        setCookiePolicyPopUp() {
            this.showCookiePolicyPopUp = getCookie("useCookies") === "true" ? false : true;
        },
        setIncognitoBrowserPopUp() {
            if(getCookie("useCookies") === "true" && getCookie("readIncognitoBrowserMessage") !== "true"){
                detectIncognito().then((result) => {
                    this.showIncognitoBrowserPopUp = result.isPrivate;
                });
            } else {
                this.showIncognitoBrowserPopUp = false;
            }
        },
    },
});