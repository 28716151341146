import { useConfigStore } from "../store/useConfigStore";
import { usePerCallsStore } from "../store/usePerCallsStore";
import { useCallsStore } from "../store/useCallsStore";
import { useMyStore } from "../store/useMyStore";
import { getUrl } from "../helpers/helpers";

const globalMixin = {
    computed: {
        myStore() {
            return useMyStore();
        },
        configStore() {
            return useConfigStore();
        },
        callsStore() {
            return useCallsStore();
        },
        perCallsStore() {
            return usePerCallsStore();
        },
    },
    methods: {
        getUrl(type) {
            return getUrl(type);
        },
    },
};

export default globalMixin;
