import { contentVersionQuery } from "./gqlStrapiQueries";
import { axiosStrapiInstance, errorHandling } from "../axiosInstances";

const getContentVersion = (myStore, skin) => {
    return axiosStrapiInstance({
        method: "post",
        data: {
            query: contentVersionQuery(),
        },
    })
        .then(function (response) {
            return response.data.data.contentVersion.data.attributes.version;
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getContentVersion");
        });
};

export { getContentVersion };
